import React, { useState } from "react";
import { graphql } from "gatsby";
import { Parallax } from "react-parallax";
import { RichText } from "prismic-reactjs";
import Layout from "../components/layouts";

const HeaderSlice = ({ content, title }) => {
  const { primary } = content;
  const { header } = primary;
  return (
    <Parallax className="page-header" bgImage={header.url} strength={400}>
      <div style={{height: '100vh', maxHeight: '550px'}}>
          <div className="page-header-overlay" />
          <h1 className="page-header-title white">{title}</h1>
      </div>
    </Parallax>
  )
}; 

const Description = ({ content }) => {
  const { primary } = content;
  const { description } = primary;
  return (
    <div className="signup-body">
      <div className="signup-intro">
        { RichText.render(description) }
      </div>
    </div>
  )
};

const Postings = ({ content }) => {
  const { fields } = content;
  const [activeItems, setActiveItems] = useState([]);
  
  const toggleActive = (title) => {
    if (activeItems.includes(title)) {
      setActiveItems(activeItems.filter((item) => item !== title));
    } else {
      setActiveItems([...activeItems, title]);
    }
  };
  return (
    <div className="signup-body">
      <div className="signup-accordion-container">
      {fields.map(item => {
        const { posting } = item;
        const { body } = posting;
        const title = body?.find(x => x.type === 'title')?.primary?.job_title || "";
        const description = body?.find(x => x.type === 'description')?.primary?.job_description || null;
        const isActive = activeItems.includes(title);
        return (
          <div
            className={`signup-accordion ${isActive ? 'active' : ''}`}
            key={title}
            onClick={() => toggleActive(title)}
          >
            <div className="signup-accordion-header">{title}</div>
            <div className="signup-accordion-body">
              { RichText.render(description) }
            </div>
          </div>
        )
      })}
    </div>
    </div>
  )
};

const Form = ({ content }) => {
  const { primary } = content;
  const { form_title, html_embed } = primary;

  return (
    <div className="signup-body">
      <div className="form-container application-form-container">
        <div className="form-inner application-form-inner">
          <h1>{form_title}</h1>
          <div dangerouslySetInnerHTML={{ __html: html_embed }} />
        </div>
      </div>
    </div>
  )
};


const sliceComponents = {
  header_slice: HeaderSlice,
  description_slice: Description,
  posting_slice: Postings,
  form_slice: Form,
};

export default ({ data }) => {
  const doc = data.prismic.allSign_ups.edges.slice(0,1).pop();
  const title = doc?.node?._meta?.uid?.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  if(!doc) return null;
  return(
    <Layout>
      <div className="signup-page">
        <div className="signup-inner">
          {doc.node?.body.map((slice, i) => {
              if (!slice?.type) return null;
              const SliceComponent =
                sliceComponents[slice.type];

                if (SliceComponent) {
                  return (
                    <SliceComponent
                      content={slice}
                      key={slice.id || i}
                      title={title}
                    />
                  );
                }

                return null;
            })}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
query SignUpQuery($uid: String) {
  prismic{
    allSign_ups(uid: $uid) {
      edges {
        node {
          body {
            ... on PRISMIC_Sign_upBodyHeader_slice {
              type
              label
              primary {
                header
              }
            }
            ... on PRISMIC_Sign_upBodyDescription_slice {
              type
              label
              primary {
                description
              }
            }
            ... on PRISMIC_Sign_upBodyForm_slice {
              type
              label
              primary {
                form_title
                html_embed
              }
            }
            ... on PRISMIC_Sign_upBodyPosting_slice {
              type
              label
              fields {
                posting {
                  ... on PRISMIC_Job_posting {
                    _linkType
                    body {
                      ... on PRISMIC_Job_postingBodyTitle {
                        type
                        label
                        primary {
                          job_title
                        }
                      }
                      ... on PRISMIC_Job_postingBodyDescription {
                        type
                        label
                        primary {
                          job_description
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          _meta {
            uid
          }
        }
      }
    }
  }
}
`
